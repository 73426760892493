
$footer-size: 50px;

.footerContainer {
  width:100%;
  height: $footer-size;
  bottom:0;
  position: absolute;
}

.mainContainer {
  position: relative;
  padding-bottom: 50px;
  min-height: calc(100vh - 33px);
}
