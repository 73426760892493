@charset "utf-8";

@import './theme.scss';


body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


label.label span.info {
  display: block;
  font-weight: 100;
  font-size: 11px;
  letter-spacing: -0.4px;
}
.field.is-horizontal .field-label {
  padding-top: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  font-size: 15px;
  line-height: 16px;
}