
.headerSpacer {
  height: 100px;
}

.header {
  background: #666666;
  position: fixed;
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  z-index: 99;
  .headerLogo {
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
      max-width: 100%;
      height: auto;
      max-height: 40px;
    }
  }
  .menuEnd {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    color: white;
    padding: 15px;
    font-size: 12px;
    text-align: right;
  }
  .menuStart {
    display: flex;
    flex-direction: row;
    & > a {
      text-align: center;
      color: #ccc;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding: 0 2vw;
      font-weight: 450;
      border-bottom: 4px solid #ccc;
      &:hover {
        color: white;
        border-bottom: 4px solid white;
      }
      &.active {
        border-bottom: 4px solid #FD7F00;
        color: white;
      }
    }
  }
}
