
.footer {
  padding: 20px 0;
  font-size: 12px;
  color: #888;
  .content {
    font-size: 12px;
  }
  ul {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: -5px 0 0 0 !important;
    li {
      margin: 0 12px !important;
      &:first-child {
        list-style: none;
        margin-left: 0 !important;
      }
    }
  }
}
