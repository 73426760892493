
/* override `bulma.io` variables */
$primary: #FD7F00;
$navbar-height: 5rem;
$radius: 4px;
$input-shadow: none;
$box-shadow: none;
$blue: hsl(204, 86%, 53%);
$green: #22a14e;
$link: $primary;
$info: $primary;

.box {
  border: 1px solid #dbdbdb;
}

/* override `bulma.io` classes */
@media screen and (max-width: 1279px) {
  .container.is-widescreen { margin: 0 1%; }
}
.dropdown-item {
  cursor: pointer;
}

/* import `bulma.io` css framework */
@import '~bulma/bulma.sass'
